// ==== Module ===========================================================
// Hero
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Hero {
  background-color: module-color(block-light-bg);
  font-family: $font-family-heading;
  padding: unit(2);
}


.Title {
  font-size: rem($h4-font-size-l);
}