// ==== Module ===========================================================
// Header
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Header {
  background-color: module-color(header-bg);
  box-shadow: $card-box-shadow;
  display: grid;
  grid-template-columns: rem(120) auto;
  height: rem(48);
  padding-left: unit(2);
  padding-right: unit(2);
  position: sticky;
  top: 0;
  width: 100%;
}


.Nav {
  align-self: center;
  justify-self: end;

  &Link {
    color: module-color(header-color);
    display: inline-block;
    margin-left: unit(1);
    padding: unit(1);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
