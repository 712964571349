// ==== Module ===========================================================
// Input Field
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Field {
  position: relative;
  padding-top: unit(2);
  padding-bottom: unit(2);
}

.Label {
  display: block;
  padding-bottom: unit(1);
}

.Input {
  background-color: module-color(form-v2-input-bg);
  border: 1px solid module-color(form-v2-input-border);
  color: module-color(form-v2-input-color);
  display: block;
  font-size: rem($font-default-size);
  padding: unit(1) unit(2);
  width: 100%;
}
