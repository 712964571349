// ==== Component ========================================================
// Button
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Button {
  background-color: module-color(store-button-bg);
  color: module-color(store-button-color);
  padding: unit(1) unit(2);
  text-decoration: none;
}
