// ==== Module ===========================================================
// Brand
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Brand {
  align-items: center;
  display: inline-grid;
  grid-template-columns: rem(32) auto;
  height: 100%;
}

.Logo {
  white-space: nowrap;
}

.Title {
  color: module-color(logo-default-color);
  font-family: $font-family-heading;
  font-size: rem($h5-font-size-l);
  margin: 0;
  padding-top: rem(8);
}
