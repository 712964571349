
#swagger-editor {
  font-size: 1.3em;
}

.container {
  height: 100%;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

#editor-wrapper {
  height: 100%;
  border: 1em solid #000;
  border: none;
}

.Pane2 {
  overflow-y: scroll;
}
