// ==== Module ===========================================================
// Table
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.table {
  background-color: module-color(table-bg);
  border-color: module-color(table-border-color);
  border-style: solid;
  border-width: 1px 1px 0 1px;
  color: module-color(table-color);
  margin-bottom: unit(4);
  font-size: rem(12);
  width: 100%;

  &__heading,
  &__data {
    border-bottom: 1px solid module-color(table-border-color);
    padding: 0.5rem;
    vertical-align: top;
  }

  &__data {
    &--has-number {
      text-align: right;
    }
  }

  &__heading {
    background-color: module-color(table-header-bg);
    border-right: 1px solid module-color(table-border-color);
    color: module-color(table-header-color);
    user-select: none;
  }

  &__row {
    &:nth-child(even) {
      background-color: module-color(table-bg-alt);
    }
  }
}
