// ==== Module ===========================================================
// Project Detail
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Project {
  padding: unit(2);
}

.Item {
  display: grid;
  grid-template-columns: rem(240) auto;
}

.Label,
.Info {
  padding-top: unit(1);
  padding-bottom: unit(1);
}

.Label {
  text-transform: capitalize;
  &::after {
    content: ":";
  }
}
