// ==== Module ===========================================================
// Login
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Login {
  height: 100vh;
  position: relative;
  width: 100vw;
}

.Container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.Form {
  width: 320px;
  max-width: 100%;
}