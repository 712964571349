// ==== Module ===========================================================
// Filter
// =======================================================================

@import "@fueled/fdc-ui/src/scss/_global";

.Filter {
  padding: unit(m);
}

.Label {
  display: inline-block;
  margin-right: unit(2);
}

.Input {
  @extend .Label;

  padding: unit(1);
}

.Button {
  background-color: module-color(store-button-bg);
  color: module-color(store-button-color);
  padding: unit(1) unit(2);
  text-decoration: none;
}
