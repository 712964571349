@import "@fueled/fdc-ui/src/scss/crucial";

body {
  background: var(--c-light);
  color: var(--c-dark);
}

.main {
  padding-left: rem(16);
  padding-right: rem(16);
}
